<template>
  <div class="outer-page">
    <div class="sub-section">
      <div class="content">
        <ul>
          <li
            v-for="(item, index) in personList"
            :key="index"
            @click="goThere(item.id)"
          >
            <div class="team-img">
              <img :src="item.servicePath + item.cover" alt="" />
            </div>
            <h6>{{ item.name }}</h6>
            <p>
              <span style="font-family: Microsoft YaHei; font-size: 14px">{{
                briefFirst[index]
              }}</span>
            </p>
            <p>
              <span style="font-family: Microsoft YaHei; font-size: 14px">{{
                briefLast[index]
              }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      personList: [],
      briefFirst: [],
      briefLast: [],
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getList() {
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 999,
          pageNo: 1,
        }).then((res) => {
          this.personList = res.list;
          for (let i = 0; i < this.personList.length; i++) {
            this.briefFirst.push(this.personList[i].brief.split("/")[0]);
            this.briefLast.push(this.personList[i].brief.split("/")[1]);
          }
        });
      }
    },
    goThere(id) {
      this.$router.push({
        name: "mnewsInfo",
        query: {
          id: id,
          key: this.$route.name,
        },
      });
    },
  },
  mounted() {
    setTimeout(()=>{
         this.getList();
    },200);
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  .sub-section {
    padding: 0.5rem 0;
    font-size: 0.577rem;
    line-height: 1rem;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      width: 94%;
      margin: 0 3%;
      //   border: 1px solid green;
      ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 47%;
          height: 10rem;
          border: 1px #ebebeb solid;
          text-align: center;
          margin-bottom: 0.5rem;
          overflow: hidden;
          .team-img {
            display: inline-block;
            width: 4.1rem;
            height: 4.1rem;
            margin-top: 0.8rem;
            img {
              display: block;
              width: 4.1rem;
              height: 4.1rem;
              border: 1px #245f5c solid;
              border-radius: 50%;
            }
          }
          h6 {
            font-size: 0.622rem;
            padding: 0.2rem 0;
            color: #333;
            &::after {
              display: block;
              content: "";
              width: 2rem;
              height: 1px;
              background: #ebebeb;
              margin: 0.33rem auto;
            }
          }
          p {
            line-height: 0.9rem;
            overflow: hidden;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>